<template>
  <div :class="$style.home">
    <div :class="$style.header">
      <img :src="oss + '/manage/media/assets/relationship_map.png'" />
      <BjInput
        v-model="name"
        :class="$style.input"
        placeholder="搜索文化点以查看图谱"
        @search="onSearch"
        @pressEnter="onSearch"
      >
        <i slot="suffix" class="ri-search-2-line" />
      </BjInput>
    </div>
    <div :class="$style.content">
      <p v-if="!show" :class="$style.title">推荐文化点</p>
      <p v-else :class="$style.title">
        搜索到关于”
        <span class="primary">{{ showName }}</span>
        “相关的文化点
      </p>
      <Box v-if="!loading" :data="data" @change="onDetail" />
      <a-spin v-else :class="$style.spin" />
    </div>
  </div>
</template>

<script>
import { relationService } from '@/service'

import Box from './Box.vue'

const service = new relationService()

export default {
  name: 'Home',
  components: { Box },
  data() {
    return {
      data: [],
      total: 0,
      page: 1,
      pageSize: 20,
      loading: false,
      name: null,
      show: false,
      showName: '',
    }
  },
  created() {
    this.getDefault()
  },
  methods: {
    onSearch() {
      this.getList()
      if (this.name) {
        this.show = true
        this.showName = this.name
      } else {
        this.show = false
        this.showName = ''
      }
    },
    onDetail(item) {
      this.$router.push({
        name: 'resourcesRelationDetail',
        params: { id: item.id },
        query: { name: item.name },
      })
    },
    async getList() {
      try {
        this.loading = true
        const { data } = await service.list({
          name: this.name,
          page: this.page,
          page_size: this.pageSize,
        })
        this.data = data.record
        this.total = data.total
      } catch (e) {
        this.loading = false
      } finally {
        this.loading = false
      }
    },
    async getDefault() {
      const { data } = await service.list({
        page: 1,
        page_size: 10,
      })
      this.data = data.record
    },
  },
}
</script>

<style lang="less" module>
.home {
  .header {
    position: relative;
    width: 100%;
    max-height: 366px;
    text-align: center;

    .input {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 500px;
      transform: translate(-50%, -50%);

      :global {
        .ant-input {
          height: 46px;
          background: #fff;
        }

        .ant-input-suffix {
          color: #000;
          font-size: 20px;
        }
      }
    }

    img {
      max-width: 100%;
    }
  }

  .content {
    position: relative;
    min-height: 400px;
    padding: 20px;
    background: #fff;

    .title {
      margin: 0 0 20px 0;
      color: #000;
      font-size: 16px;
      text-align: left;
    }

    .spin {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
