<template>
  <div>
    <BjBox v-if="data.length">
      <div v-for="item in data" :key="item.id" :class="$style.item" @click="onClick(item)">
        <div>
          <div :class="$style.cover">
            <img :src="item.cover.cover" alt="" />
          </div>
        </div>
        <div v-if="item.poc_type_name" :class="$style.tip">
          {{ item.poc_type_name }}
        </div>
        <p :title="item.name" :class="$style.name" class="ellipsis-width">{{ item.name }}</p>
        <p :class="$style.relation">
          <span>关系数量：</span>
          <span :class="$style.num">{{ item.relation_num }}</span>
        </p>
      </div>
    </BjBox>
    <div v-else :class="$style.nodata">
      <img :src="oss + '/manage/media/state/no_data.png'" />
      <p>暂无数据</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Box',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  methods: {
    onClick(item) {
      this.$emit('change', item)
    },
  },
}
</script>

<style lang="less" module>
.item {
  width: 100%;
  padding-bottom: 14px;
  overflow: hidden;
  border-radius: 6px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);

  .cover {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 0;

    img {
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      user-select: none;
      object-fit: cover;
    }
  }

  p {
    margin: 10px 0 0 0;
  }
}

.relation {
  padding-left: 12px;
  color: #5c5c5c;
  font-size: 12px;
}

.num {
  color: @primary-color;
}

.name {
  padding-left: 12px;
  color: #000;
  font-size: 16px;
}

.tip {
  position: absolute;
  top: 30px;
  left: 10px;
  height: 24px;
  padding: 0 10px;
  color: #fff;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  background: @primary-color;
  border-radius: 0 6px 6px 0;
}

.nodata {
  margin-top: 100px;
  text-align: center;
  /* stylelint-disable-next-line no-descending-specificity */
  img {
    width: 100px;
    height: 100px;
  }
}
</style>
